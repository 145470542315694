<template>
  <div class="signIn-edit">
    <el-dialog title="编辑签到" :visible.sync="show" width="630px" :before-close="handleClose">
      <el-form ref="editForm" :model="form" label-width="150px" :rules="rules">

        <el-form-item label="签到标题：" required prop="title">
          <el-input v-model.trim="form.title" size="mini" style="width:220px;" placeholder="请输入标题" maxlength="60"
            show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="签到时间：" required class="form-item-time">
          <el-col :span="8">
            <el-form-item prop="date">
              <el-date-picker type="date" class="signIn-date" placeholder="选择日期" v-model="form.date" size="mini"
                value-format="yyyy-MM-dd" :disabled="isDisable" :picker-options="pickerOptions"
                @change="changeDate"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item prop="timeStart">
              <el-time-picker v-model="form.timeStart" placeholder="开始时间" size="mini" class="signIn-time"
                :disabled="startTimeDisabled" value-format="HH:mm:ss"
                :picker-options="{ selectableRange: pickerSatrtTime() }" @change="changeStartTime"></el-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="2"><span class="link">至</span></el-col>
          <el-col :span="7">
            <el-form-item prop="timeEnd">
              <el-time-picker v-model="form.timeEnd" placeholder="结束时间" size="mini" class="signIn-time"
                :disabled="endTimeDisabled" value-format="HH:mm:ss"
                :picker-options="{ selectableRange: pickerEndTime() }"></el-time-picker>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item label="签到绑定：" required>
          <el-radio-group v-model="form.bindType" @change="changeBindType">
            <el-radio :label="1">课程</el-radio>
            <el-radio :label="2">活动</el-radio>
            <el-radio :label="3">项目</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否限制打卡地点：" required>
          <el-radio-group v-model="form.restrictedPlace">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择课程：" ref="bindCourse" required v-show="form.bindType === 1" size="mini" prop="bindId">
          <el-select v-model="form.bindId" placeholder="请选择课程">
            <el-option v-for="item in courseList" :key="item.value" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择活动：" ref="bindActivity" required v-show="form.bindType === 2" size="mini" prop="bindId">
          <el-select v-model="form.bindId" placeholder="请选择活动">
            <el-option v-for="item in activityList" :key="item.value" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="绑定项目：" :required="bindProRequired" v-if="form.bindType === 3">
          <el-col :span="11">
            <el-form-item size="mini" :required="bindProRequired" prop="projectPeriodId" ref="bindPeriod"
              class="form-item-period">
              <el-select v-model="form.projectPeriodId" placeholder="请选择" @change="periodChange">
                <el-option v-for="item in periodList" :key="item.value" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="11">
            <el-form-item size="mini" :required="bindProRequired" prop="bindId" ref="bindProId" class="form-item-project">
              <el-cascader :options="projectList" :props="{ checkStrictly: true, value: 'id', label: 'name' }" clearable
                v-model="form.bindId" @change="projectChange" style="margin-left:20px"></el-cascader>
            </el-form-item>
          </el-col>

        </el-form-item>
        <!-- emitPath:false -->
        <el-form-item label="用户组：" :required="bindProRequired" prop="userGroupValue" ref="bindUserGroup"
          v-show="form.bindType === 3">
          <el-cascader :options="userGroup" :props="{ multiple: true, value: 'id' }" clearable filterable
            v-model="form.userGroupValue" ref="cascader" @change="userGroupChange" size="mini" collapse-tags>
          </el-cascader>
        </el-form-item>

        <el-form-item class="btns-box">
          <el-button class="cancel btn" @click="handleClose">取消</el-button>
          <el-button class="submit btn" type="primary" @click="onSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      default: ''
    },
    activeItem: {
      type: Object
    },
  },
  data() {
    return {
      show: false,
      courseList: [], //课程列表
      activityList: [], //活动列表
      periodList: [], //期次列表
      projectList: [], //二级及以下项目列表
      userGroup: [], //用户组
      userGroupValue: '', //用户组选中的id的值
      isEditSuccess: false,
      form: {
        title: '',
        date: '',
        timeStart: '',
        timeEnd: '',
        bindType: 1,
        bindId: '',
        projectPeriodId: '',
        userGroupValue: [],
        restrictedPlace: 1,
      },
      echo: { //回显值记录
        bindType: "",
        bindId: "",
        projectPeriodId: "",
        userGroupValue: "",
      },
      // 提交表单
      submitForm: {
        id: '',
        title: '',
        endTime: '',
        startTime: '',
        bindType: '',
        bindId: '',
        restrictedPlace: 1,
      },
      bindProRequired: false,
      isDisable: false, //是否禁用[日期、开始时间]
      startTimeDisabled: false,
      endTimeDisabled: false,
      pickerOptions: { //今天以前（不含今天）禁用
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      pickerSatrtTime: () => {
        let time = `${this.form.date} ${this.form.timeStart}`
        if (this.dayjs(time).unix() > this.dayjs().unix()) {
          return `00:00:00 - 23:59:59`;
        }
        let nowTime = this.dayjs().format('HH:mm:ss');
        return `${nowTime} - 23:59:59`;
      },
      pickerEndTime: () => {
        if (!this.form.timeStart) return `00:00:00 - 23:59:59`;
        let nowTime = this.dayjs().format('HH:mm:ss');
        return `${this.form.timeStart} - 23:59:59`;
      },
      rules: {
        title: { required: true, message: "请输入标题", trigger: "blur" },
        date: { required: true, message: "请选择日期", trigger: "blur" },
        timeStart: { required: true, message: "请选择开始时间", trigger: "blur" },
        timeEnd: { required: true, message: "请选择结束时间", trigger: "blur" },
        bindId: { required: true, message: "请选择", trigger: "change" },
        projectPeriodId: { required: false, message: "请选择项目分期", trigger: "change" },
        userGroupValue: { required: false, message: "请选择用户组", trigger: "change", type: 'array' },
      },
    };
  },
  watch: {
    dialogVisible(newVal, oldVal) {
      this.show = newVal;
      if (newVal) this.getSignInListData();
    },
    projectId(newVal, oldVal) {
      if (newVal === '0' || !newVal) return;
      this.getCourseList();
    },
    activeItem(newVal, oldVal) {
      if (!newVal || !this.dialogVisible) return;
      this.getSignInListData();
    },
    'form.bindId'(newVal, oldVal) {
      if (!newVal || newVal == this.echo.bindId) return;
      for (const key in this.echo) {
        this.echo[key] = "";
      }
    },
  },
  computed: {
    formatTime: function () {
      this.form.startTime = `${this.form.date} ${this.form.timeStart}`
      this.form.endTime = `${this.form.date} ${this.form.timeEnd}`
    }
  },
  methods: {
    // 改变日期
    changeDate(val) {
      this.form.timeStart = "";
      this.form.timeEnd = "";
      this.startTimeDisabled = true;
      this.endTimeDisabled = true;

      if (val) {
        this.startTimeDisabled = false;
      }
    },
    // 改变开始时间
    changeStartTime(val) {
      if (val) {
        this.endTimeDisabled = false;
        return;
      }
      this.form.timeEnd = "";
      this.endTimeDisabled = true;
    },
    // 拷贝需要提交的表单项
    copyForm() {
      // console.log(this.form);
      this.formatTime;
      if (this.form.bindType === 3) {
        this.submitForm.projectPeriodId = '';
        this.submitForm.userGroupArray = '';
        this.submitForm.userGroupId = '';
      }
      for (const key in this.submitForm) {
        this.submitForm[key] = this.form[key];
      }
      this.submitForm.projectId = this.projectId;

      if (this.submitForm.bindType === 3 && !this.submitForm.userGroupArray) {
        this.submitForm.userGroupArray = JSON.stringify(this.echo.userGroupValue);
        this.submitForm.userGroupId = this.echo.userGroupValue.join(',');
      }

      // console.log('submitForm',this.submitForm);
    },
    // 关闭创建
    handleClose(done) {
      for (const key in this.echo) {
        this.echo[key] = "";
      }
      this.$emit('close', {
        dialogVisible: false,
        isSuccess: this.isEditSuccess
      })
      this.isDisable = false;
      this.startTimeDisabled = false;
      this.endTimeDisabled = false;
      this.isEditSuccess = false;
    },
    // 查询该项签到信息 (回显)
    getSignInListData() {
      this.$Api.Course.getSignInListOne(this.activeItem.id).then(resData => {
        console.log('回显数据', resData);

        if (this.activeItem.signStatus == 2) {
          this.isDisable = true;
          this.startTimeDisabled = true;
        }

        Vue.set(this.form, 'id', this.activeItem.id)
        Vue.set(this.form, 'title', resData.data.title);
        Vue.set(this.form, 'date', this.dayjs(resData.data.startTime).format('YYYY-MM-DD'));
        Vue.set(this.form, 'timeStart', this.dayjs(resData.data.startTime).format('HH:mm:ss'));
        Vue.set(this.form, 'timeEnd', this.dayjs(resData.data.endTime).format('HH:mm:ss'));
        Vue.set(this.form, 'bindType', resData.data.bindType);
        Vue.set(this.form, 'restrictedPlace', resData.data.restrictedPlace);

        let that = this;
        let map = {
          1: {
            name: ['bindCourse'],
            echo: ['bindType', 'bindId'],
            bindProRequired: false,
            request: that.getCourseList(),
          },
          2: {
            name: ['bindActivity'],
            echo: ['bindType', 'bindId'],
            bindProRequired: false,
            request: that.getActivityList(),
          },
          3: {
            name: ['bindPeriod', 'bindUserGroup', 'bindProId'],
            echo: ['bindType', 'bindId', 'projectPeriodId', 'userGroupValue','userGroupArray','userGroupId'],
            bindProRequired: true,
            request: that.getPeriod(),
          },
        }

        map[resData.data.bindType].echo.map((item) => {
          if (item === 'userGroupValue') {
            console.log('----resData.data.userGroupId');
            Vue.set(this.echo, item, JSON.parse(resData.data.userGroupArray))
            Vue.set(this.form, item, JSON.parse(resData.data.userGroupArray));
            // Vue.set(this.form, item, [resData.data.userGroupId.split(',').map(Number)])

          }
          // else if(item === 'userGroupArray'){
          //   Vue.set(this.echo, item, JSON.parse(resData.data.userGroupArray))
          //   Vue.set(this.form, item, JSON.parse(resData.data.userGroupArray));
          //   // Vue.set(this.form, item, JSON.stringify([resData.data.userGroupId.split(',').map(Number)]))
          // }
           else {
            Vue.set(this.echo, item, resData.data[item]);
            Vue.set(this.form, item, resData.data[item]);
          }
        })

        this.bindProRequired = this.rules.projectPeriodId.required = this.rules.userGroupValue.required = map[resData.data.bindType].bindProRequired;
        map[resData.data.bindType].request;

        if (resData.data.bindType != 3) return;
        this.getPeriod().then(res => {
          this.periodChange(resData.data.projectPeriodId);
          // Vue.set(this.form,'bindId',[resData.data.bindId]);
          this.projectChange([resData.data.bindId])
          // Vue.set(this.echo,'bindId',[resData.data.bindId]);
        }).catch(err => {
          console.log(err);
        })


      }).catch(err => {
        console.log(err);
      });

    },
    // 表单验证
    onSubmit() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          this.copyForm();
          this.commitForm();
        }
      })
    },
    // 提交表单
    async commitForm() {
      console.log('提交参数:',this.submitForm)
      let resData = await this.$Api.Course.editSignIn(this.submitForm);
      if (resData.data.code === 200) {
        DoUpElement.notify(this, 'success', "创建成功")
        this.isEditSuccess = true;
        this.handleClose();
      } else {
        DoUpElement.notify(this, 'error', resData.msg)
      }
    },
    // 切换绑定模式
    changeBindType(val) {
      let that = this;
      let map = {
        1: {
          name: ['bindCourse'],
          echo: ['bindId'],
          bindProRequired: false,
          request: that.getCourseList(),
        },
        2: {
          name: ['bindActivity'],
          echo: ['bindId'],
          bindProRequired: false,
          request: that.getActivityList(),
        },
        3: {
          name: ['bindPeriod', 'bindUserGroup', 'bindProId'],
          echo: ['bindId', 'projectPeriodId', 'userGroupValue'],
          bindProRequired: true,
          request: that.getPeriod(),
        },
      }

      if (this.echo.bindType == val) {
        map[val].echo.map((item) => {
          Vue.set(this.form, item, this.echo[item]);
        })
      }
      this.bindProRequired = this.rules.projectPeriodId.required = this.rules.userGroupValue.required = map[val].bindProRequired;
      map[val].request;
      if (this.echo.bindType == val) return;
      map[val].name.map((item) => {
        that.$refs[item].resetField();
      })
    },
    //获取绑定课程列表
    getCourseList() {
      this.$Api.Form.getCourse(this.projectId)
        .then(res => {
          this.courseList = res.data
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获取绑定活动列表
    getActivityList() {
      this.$Api.Form.getActive(this.projectId)
        .then(res => {
          this.activityList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获取绑定项目分期
    getPeriod() {
      return this.$Api.Form.getPeriod(this.projectId)
        .then(res => {
          // console.log(res)
          this.periodList = res.data;
          return Promise.resolve(this.periodList)
        })
        .catch(err => {
          console.log(err);
          return Promise.reject(err)
        });
    },
    //绑定项目期次变化 获取对应 cascader
    periodChange(val) {
      let arr = this.periodList.filter(item => {
        return item.id == val
      })
      // console.log(arr);
      this.projectList = arr[0].children
    },
    //根据项目期次获取对应 cascader 获取对应的用户组
    projectChange(val,str) {
      this.form.bindId = val[val.length - 1];
      this.$Api.Form.getUserGroup(val[val.length - 1])
        .then(res => {
          console.log('用户组-====', res.data);
          this.userGroup = res.data;
        })
        .catch(err => console.log(err));
    },
    //监听用户组变化(获取用户组id数组用于传参)
    userGroupChange(val) {
      this.form.userGroupArray = JSON.stringify(val);
      let userGroups = this.$refs["cascader"].getCheckedNodes();
      let userGroupArr = [];
      userGroups.map(item => {
        userGroupArr.push(item.value);
      });
      this.form.userGroupId = userGroupArr.join(',');
    },
  },
  mounted() { },
}
</script>

<style lang="less" scoped>
.signIn-edit {
  /deep/ .el-dialog {
    border-radius: 5px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
    text-align: center;
    background-image: url("~@/assets/images/teach/create-title.png");
    background-size: 100% 100%;

    .el-dialog__title {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      line-height: 59px;
    }

    .el-dialog__headerbtn {
      top: 15px;
      right: 27px;
      font-size: 20px;

      .el-dialog__close {
        color: #fff;
      }
    }
  }

  /deep/ .el-form-item {
    margin-bottom: 15px;
  }

  /deep/ .el-input--mini .el-input__inner {
    height: 30px;
    line-height: 30px;
  }

  /deep/ .el-range-editor--mini.el-input__inner {
    height: 30px;
  }

  .form-item-time {
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
  }

  .form-item-period,
  .form-item-project {
    margin-bottom: 0;
  }

  .signIn-date {
    margin-right: 10px;
    width: 140px;

    /deep/ .el-input__inner {
      border: 0;
      font-size: 15px;
      color: #666666;
    }

    /deep/ .el-icon-date {
      font-size: 15px;
    }
  }

  .link {
    margin-right: 20px;
    font-size: 15px;
    color: #999999;
  }

  .signIn-time {
    width: 125px;
    border: 0px;

    /deep/ .el-input__inner,
    .el-date-editor.el-input__inner {
      border: 0;
      font-size: 15px;
      color: #666666;
    }

    /deep/ .el-input__prefix {
      font-size: 15px;
    }
  }

  .btns-box {
    margin-top: 37px;

    .btn {
      padding: 0 39px;
      line-height: 32px;
      border: 1px solid #508EF9;
      border-radius: 5px;
      color: #2774EE;
    }

    .submit {
      margin-left: 96px;
      color: #fff;
      background-color: #508EF9;
    }
  }
}
</style>